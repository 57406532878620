import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { api_url } from "../constants";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../store";
const Tickets = (props) => {
  <Helmet>
    <title>Bilete Teste Școala Auto</title>
    <link rel="canonical" href="/" />
  </Helmet>;
  const { setLanguage } = useContext(LanguageContext);

  const { title } = useParams();
  const [tickets, setTickets] = useState([]);
  let { language } = useParams();

  if (language == null) {
    language = "ro";
  }

  useEffect(() => {
    setLanguage(language);
  }, []);

  useEffect(() => {
    document.title = "Bilete Categoria " + title;
    axios.get(`${api_url}test/${title}`).then((res) => {
      const tickets_list = res.data;
      const local_tickets = JSON.parse(localStorage.getItem("tickets"));
      if (local_tickets !== null) {
        for (let i = 0; i < tickets_list.length; i++) {
          for (let j = 0; j < local_tickets.length; j++) {
            if (
              tickets_list[i].title == local_tickets[j].ticket_title &&
              local_tickets[j].title == title
            ) {
              tickets_list[i].answered = true;
              tickets_list[i].right_answers = local_tickets[j].right_answers;
              tickets_list[i].wrong_answers = local_tickets[j].wrong_answers;
              break;
            } else {
              tickets_list[i].answered = false;
            }
          }
        }
      } else {
        for (let i = 0; i < tickets_list.length; i++) {
          tickets_list[i].answered = false;
        }
      }
      setTickets(tickets_list);
    });
  }, []);

  return (
    <div className="mt-8 w-full px-12 sm:px-8">
      <h1>
        {language == "ro" ? "BILETE LA CATEGORIA" : "БИЛЕТЫ ПО КАТЕГОРИЯМ"}{" "}
        {title}
      </h1>
      <div className="grid grid-cols-8 gap-8 mt-4 lg:grid-cols-5 md:grid-cols-4 xl:grid-cols-7 sm:grid-cols-2">
        {tickets.map((ticket, index) => (
          <Link
            to={"/" + language + "/ticket/" + title + "/" + ticket.title}
            key={index}
            className={
              "border-1 text-center border-solid border-black p-4 hover:shadow-lg cursor-pointer hover:bg-slate-100 transition-all duration-300 ease-in-out " +
              (ticket.answered
                ? (ticket.title == "AB" && ticket.wrong_answers <= 2) ||
                  ticket.wrong_answers <= 1
                  ? "bg-green text-white hover:!bg-green"
                  : "bg-red-500 text-white hover:!bg-red-500"
                : "")
            }
          >
            <h2 className="text-xl font-bold">{ticket.title}</h2>

            <p className="text-xs font-medium mt-1">
              {ticket.questions} {language == "ro" ? "întrebări" : "вопросов"}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Tickets;
