import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { api_url, media_url } from "../constants";
import hint from "../static/images/hint.svg";
import next from "../static/images/next.svg";
import finish from "../static/images/finish.svg";
import stopwatch from "../static/images/stopwatch.svg";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../store";

const Ticket = () => {
  const { title, ticket_title } = useParams();
  const [ticket, setTicket] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showHint, setShowHint] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [allAnswered, setAllAnswered] = useState(false);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const navigate = useNavigate();
  const { setLanguage } = useContext(LanguageContext);
  const [canChoose, setCanChoose] = useState(true);

  let { language } = useParams();
  if (language == null) {
    language = "ro";
  }

  useEffect(() => {
    setLanguage(language);
  }, []);

  <Helmet>
    <title>Întrebări Teste Școala Auto</title>
    <link rel="canonical" href="/" />
  </Helmet>;

  useEffect(() => {
    document.title = "Întrebări " + ticket_title + " - " + title;
    let interval = null;
    if (isRunning) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
      }, 1000);
    } else if (!isRunning && time !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, time]);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = time - hours * 3600 - minutes * 60;

  useEffect(() => {
    axios
      .get(`${api_url}ticket/${title}/${ticket_title}`, {
        params: {
          language: language,
        },
      })
      .then((res) => {
        setTicket(res.data);
        setUserAnswers(new Array(res.data.length).fill(""));
      });
  }, [language]);

  function SubmitAnswer(answer) {
    let newAnswers = [...userAnswers];
    newAnswers[currentQuestion] = answer;
    setUserAnswers(newAnswers);

    let answered = true;
    for (let i = 0; i < newAnswers.length; i++) {
      if (newAnswers[i] == "") {
        answered = false;
        break;
      }
      if (newAnswers[i].text.length == 0) {
        answered = false;
        break;
      }
    }
    setAllAnswered(answered);
  }

  function NextQuestion() {
    if (currentQuestion < ticket.length) {
      setCurrentQuestion(currentQuestion + 1);
      setShowHint(false);
      setCanChoose(true);
    }
  }

  function FinishTicket() {
    setIsRunning(false);
    let right = 0;
    let wrong = 0;
    for (let i = 0; i < ticket.length; i++) {
      if (userAnswers[i].correct) {
        right++;
      } else {
        wrong++;
      }
    }

    const ticket_information = {
      title: title,
      ticket_title: ticket_title,
      right_answers: right,
      wrong_answers: wrong,
      time: time,
    };

    if (localStorage.getItem("tickets") === null) {
      let tickets = [];
      tickets.push(ticket_information);
      localStorage.setItem("tickets", JSON.stringify(tickets));
    } else {
      let tickets = JSON.parse(localStorage.getItem("tickets"));
      let exists = false;
      for (let i = 0; i < tickets.length; i++) {
        if (
          tickets[i].title == title &&
          tickets[i].ticket_title == ticket_title
        ) {
          tickets[i] = ticket_information;
          exists = true;
          break;
        }
      }
      if (!exists) {
        tickets.push(ticket_information);
      }
      localStorage.setItem("tickets", JSON.stringify(tickets));
    }

    navigate("/" + language + "/results");
  }

  function navigatePage(e) {
    if (e.key === "ArrowRight") {
      NextQuestion();
    }
    if (e.key === "ArrowLeft") {
      if (currentQuestion > 0) {
        setCurrentQuestion(currentQuestion - 1);
        setShowHint(false);
      }
    }
    if (e.key === "Enter") {
      allAnswered ? FinishTicket() : NextQuestion();
    }
  }

  function chooseAnswer(e) {
    if (
      e.key === "1" ||
      e.key === "2" ||
      e.key === "3" ||
      e.key === "4" ||
      e.key === "5"
    ) {
      //check if the answer is already answered
      if (canChoose) {
        let answer = ticket[currentQuestion].answers[parseInt(e.key) - 1];
        SubmitAnswer(answer);
        setCanChoose(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", navigatePage);
    // document.addEventListener("keydown", chooseAnswer);
    return () => {
      document.removeEventListener("keydown", navigatePage);
      // document.removeEventListener("keydown", chooseAnswer);
    };
  }, [currentQuestion, allAnswered, ticket.length]);

  return (
    <div className="mt-8 w-full px-12 sm:px-8">
      <div className="flex justify-between items-center sm:flex-col gap-y-4">
        <h1>
          {language == "ro"
            ? "BILETUL " + ticket_title + " LA CATEGORIA " + title
            : "БИЛЕТ " + ticket_title + " КАТЕГОРИЯ " + title}
        </h1>
        <div className="flex text-gray font-medium">
          <img src={stopwatch} className="w-6 h-6 mr-2" />
          {hours}:{minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </div>
      </div>
      <div className="flex flex-wrap mt-4 w-full">
        {ticket.map((question, index) => {
          return (
            <div
              key={index}
              className={
                "border-1 text-center w-10 h-10 flex items-center justify-center border-solid border-black p-1 transition-all ease-in-out duration-100 cursor-pointer hover:bg-gray-100 " +
                (currentQuestion === index
                  ? "font-bold border-2 bg-gray-100"
                  : "") +
                (userAnswers[index].text && userAnswers[index].text.length > 0
                  ? userAnswers[index].correct
                    ? " bg-green text-white"
                    : " bg-red-500 text-white"
                  : "")
              }
              onClick={() => {
                setCurrentQuestion(index);
                setShowHint(false);
              }}
            >
              {index + 1}
            </div>
          );
        })}
      </div>
      {ticket.length > 0 && (
        <div className="mt-8 w-full">
          <p className="border-l-solid border-l-4 border-l-slate-300 leading-8 pl-4 text-lg sm:text-base">
            <span className="mr-2 font-bold">{ticket[currentQuestion].id}</span>
            {ticket[currentQuestion].question}
          </p>
          <div className="flex mt-4 justify-between gap-x-8 md:gap-x-4 lg:gap-x-4 sm:flex-col sm:gap-y-4">
            <img
              src={media_url + ticket[currentQuestion].image}
              className="w-1/3 border-slate-300 border-solid border-1 flex h-full md:w-1/2 sm:w-full"
            />
            <div className="w-2/3 flex flex-col gap-y-4 md:w-1/2 sm:w-full">
              {ticket[currentQuestion].answers.map((answer, index) => {
                return (
                  <div
                    onClick={() => {
                      !(
                        userAnswers[currentQuestion].text &&
                        userAnswers[currentQuestion].text.length > 0
                      ) && SubmitAnswer(answer);
                    }}
                    key={index}
                    className={
                      "flex border-1 border-slate-300 border-solid p-2 cursor-pointer  transition-all duration-300 ease-in-out " +
                      (userAnswers[currentQuestion].text == answer.text
                        ? answer.correct
                          ? "bg-green hover:bg-green text-white"
                          : "bg-red-500 text-white hover:bg-red-500"
                        : userAnswers[currentQuestion].text &&
                          userAnswers[currentQuestion].text.length > 0 &&
                          userAnswers[currentQuestion].text != answer.text &&
                          answer.correct
                        ? " bg-green hover:bg-green text-white"
                        : " hover:bg-slate-100")
                    }
                  >
                    {answer.text}
                  </div>
                );
              })}
              <div className="flex w-1/2 mt-8 ml-auto gap-x-4 md:w-full lg:w-full xl:w-2/3 md:flex-col gap-y-4 md:mt-6 sm:flex-col sm:w-full">
                <button
                  disabled={showHint}
                  onClick={() => setShowHint(true)}
                  className={
                    "select-none ml-auto md:order-2 sm:order-2 md:w-full sm:w-full text-gray px-4 border-gray border-2 border-solid py-2 flex items-center justify-between w-1/2 group relative transition-opacity duration-200 ease-in-out" +
                    (showHint ? " opacity-0 cursor-default" : "")
                  }
                >
                  <div className="absolute right-0 bottom-0 h-full w-0 bg-gray group-hover:w-3 transition-all ease-in-out duration-200"></div>
                  <img src={hint} className="h-4" />
                  {language == "ro" ? "Indiciu" : "Подсказка"}
                </button>
                {allAnswered ? (
                  <button
                    onClick={FinishTicket}
                    className="select-none md:w-full sm:w-full md:order-1 sm:order-1 text-green px-4 flex items-center justify-between py-2 w-1/2 border-green border-2 border-solid group relative"
                  >
                    <div className="absolute left-0 bottom-0 h-full w-0 bg-green group-hover:w-3 transition-all ease-in-out duration-200"></div>
                    {language == "ro" ? "Finisează biletul" : "Закончить билет"}
                    <img src={finish} className="h-4 " />
                  </button>
                ) : currentQuestion < ticket.length - 1 ? (
                  <button
                    onClick={NextQuestion}
                    className="select-none flex md:w-full md:order-1 sm:order-1 sm:w-full justify-between text-green px-4 flex items-center py-2 justify-between w-1/2 border-green border-2 border-solid group relative"
                  >
                    <div className="absolute left-0 bottom-0 h-full w-0 bg-green group-hover:w-3 transition-all ease-in-out duration-200"></div>
                    {language == "ro"
                      ? "Următoarea întrebare"
                      : "Следующий вопрос"}
                    <img src={next} className="h-4 " />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className={
              "opacity-0 hidden overflow-auto transition-opacity ease-in-out duration-200 mt-4 " +
              (showHint ? "!block !opacity-100	" : "")
            }
          >
            {ticket[currentQuestion].hint}
          </div>
        </div>
      )}
    </div>
  );
};

export default Ticket;
