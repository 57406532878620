import React, { useEffect, useState, useContext } from "react";
import car_icon from "../static/images/car_icon.svg";
import tickets_icon from "../static/images/tickets_icon.svg";
import success_icon from "../static/images/success_icon.svg";
import failure_icon from "../static/images/failure_icon.svg";
import stopwatch_icon from "../static/images/stopwatch_icon.svg";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../store";
const Results = () => {
  const [tickets, setTicket] = useState([]);
  const [times, setTimes] = useState([]);

  <Helmet>
    <title>Rezultate Teste Școala Auto</title>
    <link rel="canonical" href="/results" />
  </Helmet>;

  const { setLanguage } = useContext(LanguageContext);
  let { language } = useParams();
  if (language == null) {
    language = "ro";
  }

  useEffect(() => {
    setLanguage(language);
  }, []);

  useEffect(() => {
    document.title = "Rezultate Teste Școala Auto";
    let tickets = JSON.parse(localStorage.getItem("tickets"));
    setTicket(tickets);
    const times_list = [];
    if (tickets === null) {
      setTicket([]);
      return;
    }
    for (let i = 0; i < tickets.length; i++) {
      const time = tickets[i].time;
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time - hours * 3600) / 60);
      const seconds = time - hours * 3600 - minutes * 60;
      const time_string = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      times_list.push(time_string);
    }
    setTimes(times_list);
  }, []);
  return (
    <div className="mt-8 w-[80%] px-11 lg:w-full xl:w-[90%] md:w-full sm:w-full sm:px-8">
      <h1>{language == "ro" ? "REZULTATELE TESTELOR" : "РЕЗУЛЬТАТЫ ТЕСТА"}</h1>
      <div className="grid grid-cols-5 text-base mt-4">
        <div className="bg-slate-100 px-4 py-1 font-medium border-1 flex items-center gap-x-3">
          <span className="sm:hidden">
            {language == "ro" ? "Categorie" : "Категория"}
          </span>{" "}
          <img src={car_icon} className="h-4 sm:h-8 flex ml-auto sm:mx-auto" />
        </div>
        <div className="bg-slate-100 px-4 py-1 font-medium border-1 flex items-center gap-x-3">
          <span className="sm:hidden">
            {language == "ro" ? "Număr bilet: " : "Номер билета"}
          </span>{" "}
          <img
            src={tickets_icon}
            className="h-4 sm:h-8 flex ml-auto sm:mx-auto"
          />
        </div>
        <div className="bg-slate-100 px-4 py-1 font-medium border-1 flex items-center gap-x-3">
          <span className="sm:hidden">
            {language == "ro" ? "Răspunsuri corecte" : "Правильные ответы"}
          </span>
          <img
            src={success_icon}
            className="h-4 sm:h-8 flex ml-auto sm:mx-auto"
          />
        </div>
        <div className="bg-slate-100 px-4 py-1 font-medium border-1 flex items-center gap-x-3">
          <span className="sm:hidden">
            {language == "ro" ? "Răspunsuri greșite" : "Неправильные ответы"}
          </span>
          <img
            src={failure_icon}
            className="h-4 sm:h-8 flex ml-auto sm:mx-auto"
          />
        </div>
        <div className="bg-slate-100 px-4 py-1 font-medium border-1 flex items-center gap-x-3 sm:py-3">
          <span className="sm:hidden">
            {language == "ro" ? "Timp necesar" : "Время необходимое"}
          </span>
          <img
            src={stopwatch_icon}
            className="h-4 sm:h-8 flex ml-auto sm:mx-auto"
          />
        </div>
        {tickets &&
          tickets.map((ticket, index) => (
            <>
              <div className="px-4 py-1 border-1 sm:text-center">
                {ticket.title}
              </div>
              <div className="px-4 py-1 border-1 sm:text-center">
                {ticket.ticket_title}
              </div>
              <div className="px-4 py-1 border-1 sm:text-center">
                {ticket.right_answers}
              </div>
              <div className="px-4 py-1 border-1 sm:text-center">
                {ticket.wrong_answers}
              </div>
              <div className="px-4 py-1 border-1 sm:text-[0.6rem] sm:text-center">
                {times[index]}
              </div>
            </>
          ))}
      </div>
      {!tickets ||
        (tickets.length === 0 && (
          <p className="mt-4">
            {language == "ro"
              ? "Nu ați completat încă nici un bilet. Reveniți mai târziu!"
              : "Вы еще не заполнили ни одного билета. Вернитесь позже!"}
          </p>
        ))}
    </div>
  );
};

export default Results;
