import React, { useState, useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./styles/output.css";
import Tests from "./screens/Tests";
import Tickets from "./screens/Tickets";
import Logo from "./static/images/logo.svg";
import { Link } from "react-router-dom";
import Ticket from "./screens/Ticket";
import Results from "./screens/Results";
import results_icon from "./static/images/results_icon.svg";
import { LanguageContext } from "./store";
import ro from "./static/images/ro.svg";
import ru from "./static/images/ru.svg";

function App() {
  let local_language = localStorage.getItem("language");
  const [language, setLanguage] = useState(
    local_language !== null ? local_language : "ro"
  );
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/" + language);
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <div className="min-h-screen flex flex-col">
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <div>
          <nav className="px-12 mx-auto py-4 flex justify-between items-center sm:flex-col sm:gap-y-4">
            <Link to={"/" + language}>
              <img src={Logo} alt="logo" className="h-20 w-fit" />
            </Link>
            <Link
              to={"/" + language + "/results"}
              className="flex gap-x-2 font-medium opacity-50 hover:opacity-75 ease-in-out transition-opacity duration-200"
            >
              <div>
                {language == "ro"
                  ? "Vezi Rezultatele"
                  : "Посмотреть результаты"}
              </div>
              <img src={results_icon} className="h-6" />
            </Link>
          </nav>
          <div className="relative w-full pb-6">
            <hr className="border-1 border-black border-solid opacity-40" />
            <img
              onClick={() =>
                setLanguage(
                  language == "ro" ? "ru" : language == "ru" ? "ro" : "ro"
                )
              }
              src={language == "ro" ? ro : ru}
              className="border-1 border-solid border-black h-6 absolute right-0 mx-12 cursor-pointer w-fit sm:right-1/2 w-fit sm:transform sm:translate-x-1/2 sm:m-0"
            />
          </div>
          <Routes>
            <Route path="/:language" exact element={<Tests />} />
            <Route path="/:language/test/:title" element={<Tickets />} />
            <Route
              path="/:language/ticket/:title/:ticket_title"
              element={<Ticket />}
            />
            <Route path="/:language/results" element={<Results />} />
          </Routes>
        </div>
      </LanguageContext.Provider>
      <div className="flex justify-between px-8 bg-green py-2 mt-auto text-white font-bold text-sm">
        <div>Copyright © {new Date().getFullYear()} Teste Auto</div>
        <Link to="https://scriptify.ro" target="_blank">
          Made by Scriptify
        </Link>
      </div>
    </div>
  );
}

export default App;
