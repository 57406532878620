import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../store";
import check_answers from "../static/images/check-answers.svg";
import check_time from "../static/images/check-time.svg";
const Tests = (props) => {
  <Helmet>
    <title>Teste Școala Auto</title>
    <link rel="canonical" href="/" />
  </Helmet>;

  const { setLanguage } = useContext(LanguageContext);
  let { language } = useParams();
  if (language == null) {
    language = "ro";
  }
  useEffect(() => {
    setLanguage(language);
  }, []);

  const [tests, setTests] = useState([]);
  useEffect(() => {
    document.title = "Teste Școala Auto";
    axios.get(`${api_url}tests/`).then((res) => {
      const tests = res.data;
      const tickets = JSON.parse(localStorage.getItem("tickets"));

      if (tickets !== null) {
        for (let i = 0; i < tests.length; i++) {
          tests[i].completed_tickets = 0;
          for (let j = 0; j < tickets.length; j++) {
            if (tests[i].title === tickets[j].title) {
              tests[i].completed_tickets++;
            }
          }
        }
      } else {
        for (let i = 0; i < tests.length; i++) {
          tests[i].completed_tickets = 0;
        }
      }

      setTests(res.data);
    });
  }, []);
  return (
    <div className="px-12  sm:px-8">
      <div className="mt-8 w-2/3  xl:w-[75%] lg:w-[90%] md:w-full sm:w-full ">
        <h1>
          {language == "ro"
            ? "ALEGE CATEGORIA PENTRU TESTARE"
            : "ВЫБЕРИТЕ КАТЕГОРИЮ ДЛЯ ТЕСТИРОВАНИЯ"}
        </h1>
        <div className="grid grid-cols-3 gap-8 mt-4 md:grid-cols-2 sm:grid-cols-1">
          {tests.map((test, index) => (
            <Link
              to={"/" + language + `/test/${test.title}`}
              key={index}
              className="border-1 border-solid flex border-black p-4 items-center justify-between hover:shadow-lg cursor-pointer hover:bg-slate-100 transition-all duration-300 ease-in-out"
            >
              <h2 className="text-3xl font-bold">{test.title}</h2>
              <img src={media_url + test.icon} className="h-12" />
              <div className="font-medium">
                {test.completed_tickets}/{test.tickets}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <hr className="mt-8 h-1 bg-black/50" />
      <div className="flex flex-col mt-8 gap-y-8">
        <div className="flex gap-x-4 items-start">
          <img src={check_answers} className="w-14" />
          <div className="flex flex-col gap-y-1">
            <p>
              {language == "ro"
                ? "Dacă ai făcut mai mult de două greșeli la categoria AB, nu ați trecut."
                : "Если вы сделали больше двух ошибок в категории AB, вы не прошли."}
            </p>
            <p>
              {language == "ro"
                ? "Dacă faceți mai mult de o greșeală în categoriile C, D, E, F, nu ați trecut."
                : "Если вы сделаете больше одной ошибки в категориях C, D, E, F, вы не прошли."}
            </p>
          </div>
        </div>
        <div className="flex gap-x-4 items-start">
          <img src={check_time} className="w-14" />
          <div className="flex flex-col gap-y-1">
            <p>{language == "ro" ? "AB - 30 de minute" : "AB - 30 минут"}</p>
            <p>
              {language == "ro"
                ? "BE, C, D, F - 38 de minute"
                : "BE, C, D, F - 38 минут"}
            </p>
            <p>
              {language == "ro" ? "CE, DE - 45 de minute" : "CE, DE - 45 минут"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tests;
